import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  // const nouns = allNounsJson.nodes.map(node => node.title)
  const { frontmatter, body, rawBody } = data.mdx
  const shortcodes = {}

  return (
    <Layout location={frontmatter.slug} title={"Der Die Das Train"}>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description}
        lang={frontmatter.know}
        keywords={frontmatter.keywords}
        canonical={
          frontmatter.canonical
            ? `https://der-die-das-train.com/${frontmatter.canonical}`
            : null
        }
        slug={frontmatter.slug}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline" style={{fontWeight:'700'}}>{frontmatter.title}</h1>
        </header>
        <MDXProvider components={shortcodes}>
          <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
        </MDXProvider>
        <hr />
        <footer>
          <p style={{ fontSize: "smaller" }}>
            <span>Last Updated </span>
            {frontmatter.date}
          </p>
        </footer>
      </article>
    </Layout>
  )
}
export const pageQuery = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      rawBody
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        description
        keywords
        title
        learn
        know
        legal
      }
    }
  }
`

/*
function findAllWordsWithUppercase(data) {
  // Replace unicode double quotes with standard double quotes
  const replacedData = data.replace(/\\u201C|\\u201D/g, '"');

  // Remove all non-word characters and special characters
  const cleanedData = replacedData.replace(/[\.,;"\(\)\[\]\{\}\n\\]/g, ' ');

  // Match all words in the cleaned data
  const words = cleanedData.match(/\b\w+\b/g);

  // Filter words that start with an uppercase character
  const uppercaseWords = words.filter(word => /^[A-Z]/.test(word));

  return uppercaseWords;
}
function getCommonWords(nouns, uppercaseWordsArray) {
  const commonWords = uppercaseWordsArray.filter(word => nouns.includes(word));
  
  // Remove duplicates by converting the array into a Set and then back into an array
  const uniqueCommonWords = [...new Set(commonWords)];

  return uniqueCommonWords;
}
  {JSON.stringify(getCommonWords(nouns,findAllWordsWithUppercase(body)))}

    allNounsJson {
      nodes {
        title
      }
    }
*/
