import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header


  if (isRootPath) {
    header = (
      <header style={{width:'100%', display:"flex",flexDirection:'row',alignItems:'center'}}>
              <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/icon.png"
        width={50}
        height={50}
        quality={95}
        alt="Application icon"
      />
      <h1 className="main-heading" style={{textAlign:'center'}}>
        
        <Link to={location?.includes('/beitrag-lesen/') ?'/de/showcase' : "/showcase"}>{title}</Link>
      </h1>
      </header>

    )
  } else {
    header = (
      <header style={{width:'100%', display:"flex",flexDirection:'row',alignItems:'center'}}>
                      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/icon.png"
        width={50}
        height={50}
        quality={95}
        alt="Application icon"
      />
      <Link className="header-link-home" to={location?.includes('/beitrag-lesen/') ?'/de/showcase' : "/showcase"}>
        {title}
      </Link>
      </header>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}
        {` `}
        <a href="https://der-die-das-train.com">der-die-das-train.com</a>
      </footer>
    </div>
  )
}

export default Layout
